import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import styled from "styled-components"
import { Layout } from "src/components/layout"
import hr from "src/images/hr.png"

const StoryContainer = styled.div `
  display: flex;
  flex-direction: column;
`

const StoryHeader = styled.div `
  text-align: center;
  margin-top: 1rem;

  @media (min-width: 1024px) {
    margin-top: 2.5rem;
  }
`

const StoryLabel = styled.div `
  color: #C22700;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1px;
`

const StoryTitle = styled.h1 `
  margin-top: 1.5rem;
  margin-bottom: 0;
  color: #000;
  font-size: 2.5rem;
  /* font-weight: 300; */
  /* text-transform: uppercase; */
`

const StoryByline = styled.div `
  margin-top: 1.5rem;
  font-size: 1.25rem;
  font-weight: 700;
  color: #000;
  margin-bottom: 1.5rem;
`

const StoryCover = styled.img `
  margin-top: 3rem;
`

const StoryBody = styled.div `
  font-family: "EB Garamond";
  margin: 2rem auto 0;
  max-width: 600px;
  
  font-size: 1.25rem;
  line-height: 1.6;

  & > p:first-child:first-letter {
    color: #C02907;
    float: left;
    font-size: 75px;
    line-height: 60px;
    padding-top: 2px;
    padding-right: 8px;
    padding-left: 3px;
    font-family: "EB Garamond";
  }

  & > hr {
    margin: 4rem 0;
    height: 6px;
	  background: url(${hr}) repeat-x 0 0;
    border: 0;
  }

  & > hr + p:first-letter {
    font-family: "EB Garamond";
    color: #C02907;
    float: left;
    font-size: 75px;
    line-height: 60px;
    padding-top: 2px;
    padding-right: 8px;
    padding-left: 3px;
  }
`

const StoryTemplate = (props) => {

  const post = props.data.markdownRemark;
  const site = props.data.site;

  return (
    <Layout>
      <Helmet title={`${post.frontmatter.title} | ${site.siteMetadata.title}`} />
      <StoryContainer>
        <StoryHeader>
          <StoryLabel>Fiction</StoryLabel>
          <StoryTitle>{post.frontmatter.title}</StoryTitle>
          <StoryByline>By Cristina Hartmann</StoryByline>
        </StoryHeader>
        {post.frontmatter.cover && <StoryCover src={post.frontmatter.cover.childImageSharp.fixed.src} />}
        <StoryBody dangerouslySetInnerHTML={{ __html: post.html }} />
      </StoryContainer>
    </Layout>
  );
}

export default StoryTemplate

export const query = graphql`
  query StoryPostQuery($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        cover {
          childImageSharp {
            fixed(width: 960) {
              width
              height
              src
              srcSet
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`;
